import React from "react"
import { Container, ImageWrapper } from "./style"
import { GatsbyImage } from "gatsby-plugin-image"
import { Section } from "../../../globalStyle"
import ICompanies from "./types"

const Companies = (props: ICompanies) => {
  const { companies } = props
  return (
    <Section>
      <Container>
        {companies.allFile.edges.map((p, idx) => (
          <ImageWrapper key={idx}>
            <GatsbyImage
              image={p.node.childImageSharp.gatsbyImageData}
              alt={p.node.name}
            />
          </ImageWrapper>
        ))}
      </Container>
    </Section>
  )
}

export default Companies
