import styled from "styled-components"

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const IconWrapper = styled.div`
  & > svg {
    width: 54px;
    height: 54px;
    color: ${props => props.theme.colors.orange};
  }
  @media (max-width: 480px) {
    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`
