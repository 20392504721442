import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  margin-bottom: 30px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const ElementsWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
