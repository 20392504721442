import styled from "styled-components"

export const Container = styled.div`
  margin: 50px 0 50px 0;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 130px;
`
