import { useStaticQuery, graphql } from "gatsby"

const useDigitalTransformationQuery = () => {
  const digitalTransformationQuery = useStaticQuery(graphql`
    query digitalTransformationQuery {
      allFile(
        filter: {
          extension: { eq: "png" }
          absolutePath: { regex: "/images/partners/" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  return digitalTransformationQuery
}
export default useDigitalTransformationQuery
