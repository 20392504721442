import styled, { keyframes } from "styled-components"

const AnimBounce = keyframes`
0% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(-20%);
  }
`

export const Container = styled.div`
  width: 1470px;
  margin-bottom: 50px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`
export const ButtonWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`
export const DoubleClutchGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`

export const ImageWrapper = styled.div`
  margin: 100px 15px auto auto;

  & > a > div {
    animation: ${AnimBounce} 3s infinite;
    filter: drop-shadow(0px 8px 3px #00000030);
  }
  @media (max-width: 780px) {
    margin: 60px auto 50px auto;
  }
`
