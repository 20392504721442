import React, { PropsWithChildren } from "react"
import { P, H3Title } from "../../../globalStyle"
import { InlineIcon } from "@iconify/react"
import ITitledListItem from "./types"
import { ItemWrapper, IconWrapper } from "./style"
import { useTheme } from "styled-components"

const TitledListItem = (props: PropsWithChildren<ITitledListItem>) => {
  const theme = useTheme()
  const { icon, title, children } = props
  return (
    <ItemWrapper>
      <IconWrapper>
        <InlineIcon style={{ marginRight: "10px" }} icon={icon} />
      </IconWrapper>
      <div>
        <H3Title isDarkBlue={true}>{title}</H3Title>
        <P>{children}</P>
      </div>
    </ItemWrapper>
  )
}

export default TitledListItem
