import checkCircle from "@iconify-icons/system-uicons/check-circle"
import xCircleThin from "@iconify-icons/ph/x-circle-thin"

export const prosData = [
  {
    id: 1,
    title: "Economical",
    text: "No recruitment costs",
    icon: checkCircle,
  },
  {
    id: 2,
    title: "Quick",
    text: "Starting the project with an already integrated team",
    icon: checkCircle,
  },
  {
    id: 3,
    title: "Flexible",
    text: "Flexible Easy team scaling",
    icon: checkCircle,
  },
]

export const consData = [
  {
    id: 1,
    title: "Expensive",
    text:
      "Cost of recruiting one senior developer 6 000 – 10 000 €. Cost of recruiting one mid developer 5 000 – 7 000 €. Costs estimated according to remuneration market rate of recruitment companies",
    icon: xCircleThin,
  },
  {
    id: 2,
    title: "Laborious",
    text: "The necessity of integrating the team",
    icon: xCircleThin,
  },
  {
    id: 3,
    title: "Time-consuming",
    text: "Recruitment time is at least 1 month of the quit notice",
    icon: xCircleThin,
  },
  {
    id: 4,
    title: "Lack of flexibility",
    text: "Adding a new team member demands a new recruitment process",
    icon: xCircleThin,
  },
]
