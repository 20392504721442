import React from "react"
import { Container } from "./style"
import { H2Title, Section, P } from "../../../globalStyle"
import IQuickContact from "./types"

const QuickContact = (props: IQuickContact) => {
  const { title, text, button } = props
  return (
    <Section>
      <Container>
        <H2Title center={true} style={{ margin: "0px" }}>
          {title}
        </H2Title>
        <P center={true}>{text}</P>
        {button}
      </Container>
    </Section>
  )
}

export default QuickContact
