import React from "react"
import {
  Section,
  M,
  P,
  H2Title,
  OrangeSpan,
  DoubleGrid,
} from "../../../globalStyle"
import { Container } from "./style"
import {
  prosData,
  consData,
} from "../../../data/components/solidBussinessSupportListItem"
import TitledListItem from "../../UI/TitledListItem"

const SolidBussinessSupport = () => {
  return (
    <Section>
      <Container>
        <H2Title center={true}>
          Solid support for <OrangeSpan>your business</OrangeSpan>
        </H2Title>
        <M>
          <P center={true}>
            Do you want to implement an idea that will improve the quality of
            your services but you are not sure how to do it? You need the
            support of competent developers. A typical programming team consists
            of 8 people (3 senior developers + 5 mid developers). See below why
            more and more companies use team leasing services.
          </P>
        </M>
      </Container>
      <Container>
        <DoubleGrid>
          <M>
            {prosData.map(i => (
              <TitledListItem key={i.id} title={i.title} icon={i.icon}>
                {i.text}
              </TitledListItem>
            ))}
          </M>
          <M>
            {consData.map(i => (
              <TitledListItem key={i.id} title={i.title} icon={i.icon}>
                {i.text}
              </TitledListItem>
            ))}
          </M>
        </DoubleGrid>
      </Container>
    </Section>
  )
}

export default SolidBussinessSupport
