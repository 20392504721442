import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  width: 1470px;
  margin-bottom: 50px;
  padding: 15px;
  & > h2 {
    margin-bottom: 0;
  }
  @media (max-width: 1470px) {
    width: 100%;
  }
`
