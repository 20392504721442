import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  margin-bottom: 50px;
  z-index: 400;
  & > div:nth-child(1) {
    margin-bottom: 100px;
  }

  @media (max-width: 1470px) {
    width: 100%;
  }
`
