import React from "react"
import { Container } from "./style"
import {
  H2Title,
  H3Title,
  Section,
  P,
  M,
  AnimatedBackground,
  TripleGrid,
} from "../../../globalStyle"
import { useTheme } from "styled-components"

const BetOnTeam = () => {
  const theme = useTheme()
  return (
    <Section>
      <AnimatedBackground />
      <Container>
        <H2Title
          style={{ marginLeft: "15px", marginRight: "15px" }}
          color={theme.colors.white}
          center={true}
        >
          Bet on our well-knit team
        </H2Title>
        <TripleGrid>
          <M>
            <H3Title>Experience</H3Title>
            <P color={theme.colors.white}>
              We have worked with real estate, insurance, FinTech companies as
              well as with startups and the EU commission.
            </P>
          </M>
          <M>
            <H3Title>Flexibility</H3Title>
            <P color={theme.colors.white}>
              As an elastic Software House, we provide scrum teams on-site and
              remotely.
            </P>
          </M>
          <M>
            <H3Title>Smart Team</H3Title>
            <P color={theme.colors.white}>
              Our teams consist of well-knit professionals that are ready to
              immediately start the project.
            </P>
          </M>
        </TripleGrid>
      </Container>
    </Section>
  )
}

export default BetOnTeam
