import React from "react"

import { Container } from "./style"
import { H2Title, OrangeSpan, Section, P, Q } from "../../../globalStyle"
import ITrustedUs from "./types"

const TrustedUs = (props: ITrustedUs) => {
  const { text, name, who } = props
  return (
    <Section>
      <Container>
        <div style={{ padding: "15px" }}>
          <H2Title center={true}>
            They <OrangeSpan>trusted us</OrangeSpan>
          </H2Title>
          <Q>
            <P center={true}>{text}</P>
          </Q>
          <P center={true}>{name}</P>
          <P center={true}>{who}</P>
        </div>
      </Container>
    </Section>
  )
}

export default TrustedUs
