import React from "react"
import {
  Container,
  TextWrapper,
  ButtonWrapper,
  DoubleClutchGrid,
  ImageWrapper,
} from "./style"
import { H2Title, Section, OrangeSpan, P } from "../../../globalStyle"
import IClutchTitle from "./types"
import { StaticImage } from "gatsby-plugin-image"

const ClutchTitle = (props: IClutchTitle) => {
  const { title, coloredTitle, text, Button } = props
  return (
    <Section>
      <Container>
        <DoubleClutchGrid>
          <div>
            <H2Title
              style={{ marginBottom: "0px" }}
              marginLeft="15px"
              thin={true}
            >
              {title} <OrangeSpan>{coloredTitle}</OrangeSpan>
            </H2Title>
            <TextWrapper>
              <P>{text}</P>
            </TextWrapper>
            <ButtonWrapper>
              <Button />
            </ButtonWrapper>
          </div>
          <ImageWrapper>
            <a href="https://clutch.co/profile/oakfusion">
              <StaticImage
                src="../../../data/images/clutch2-oxywi2n1yaldr88tezlvxgn6bb0ow0esfknbpm7ski.png"
                alt="clutch"
                placeholder="tracedSVG"
              />
            </a>
          </ImageWrapper>
        </DoubleClutchGrid>
      </Container>
    </Section>
  )
}

export default ClutchTitle
