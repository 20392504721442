import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import QuickContact from "../components/Sections/QuickContact"
import Button from "../components/UI/Button"
import { OrangeSpan, GrayBorder, Section } from "../globalStyle"
import Companies from "../components/Sections/Companies"
import useDigitalTransformationQuery from "../helpers/queries/digitalTransformationQuery"
import TrustedUs from "../components/Sections/TrustedUs"
import PageTitle from "../components/Elements/PageTitle"
import ClutchTitle from "../components/Sections/ClutchTitle"
import BetOnTeam from "../components/Sections/BetOnTeam"
import SolidBussinessSupport from "../components/Sections/SolidBussinessSupport"

const TeamLeasing = () => {
  const digitalTransformationData = useDigitalTransformationQuery()
  return (
    <Layout>
      <Head
        description="💻 Team leasing 👉 we provide scrum teams on-site and remotely 💡 Don’t waste time on long recruitment processes. Hit us up ✅"
        pageTitle="Team Leasing | Bet On Our Well-Knit Team"
        keywords="software, oakfusion"
      />
      <div style={{ marginBottom: "150px" }}></div>
      <Section>
        <PageTitle title="Team Leasing" />
      </Section>
      <GrayBorder />
      <ClutchTitle
        title="Expand your team with"
        coloredTitle="great talents"
        text="e offer team leasing services. This type of cooperation grants access to all our best programmers with different skills and competences. Leasing a team from Oakfusion means kick-starting your project and saving lots of money and time that would be otherwise spent on recruiting new developers"
        Button={() => <Button slug="/contact">Order free consultation</Button>}
      />
      <BetOnTeam />
      <SolidBussinessSupport />
      <TrustedUs
        text="Oakfusion’s teamwork contributed to the client’s increase in sales since their collaboration. They were effective in adjusting to the client’s workflow and seamlessly acted as a member of the team."
        name="CPO & Founder"
        who="Contract Management Company"
      />
      <Companies companies={digitalTransformationData} />
      <QuickContact
        title={
          <>
            Got other <OrangeSpan>ideas?</OrangeSpan>
          </>
        }
        text={"Make it happen"}
        button={<Button slug="/contact">Contact</Button>}
      />
    </Layout>
  )
}

export default TeamLeasing
